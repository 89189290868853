(function () {
  const outingEventsAutoTeeTimesApp = function (form) {
    const { fields } = form;
    const {
      first_time,
      interval_one,
      interval_two,
      teams_per_time,
      first_hole,
      split_tee_times_side,
      first_nine_time,
      last_nine_time,
    } = fields;
    const timeForAll = fields.time_for_all === "1";

    const setTime = (elements, time) => {
      // if (!$('body').attr('class').includes('drop_ins'))
      //   return elements.val(time.format('hh:mm A'));

      for (let i = 0; i < elements.length; i++) {
        let element = elements[i];

        $(element).find("select").addClass("prevent-open-next");

        const hour = time.format("hh");
        const minute = time.format("mm");
        const meridiem = time.format("A");
        $(element).find("[name*=hour]").val(hour).trigger("change");
        $(element).find("[name*=minute]").val(minute).trigger("change");
        $(element).find("[name*=meridiem]").val(meridiem).trigger("change");

        setTimeout(() => $(element).find("select").removeClass("prevent-open-next"), 1000);
      }
    };

    const closeModal = () => $("#modal").modal("hide");

    const timesTarget = () => {
      if ($("body").attr("class").includes("drop_ins")) return ".time-inputs";
      return ".tee-groups .tee-group";
    };

    const firstDateTime = moment(first_time);
    if (timeForAll) {
      setTime($(timesTarget()), firstDateTime);
      return closeModal();
    }

    const setTeeTimes = (teeGroups, firstTime) => {
      const intervals = [interval_one];
      if (interval_two !== "") intervals.push(interval_two);

      const chunks = window.sliceIntoChunks(teeGroups, parseInt(teams_per_time));
      let dateTime = firstTime;
      for (let i = 0; i < chunks.length; i++) {
        let chunk = chunks[i];
        setTime($(chunk), dateTime);

        let interval = intervals.length > 0 ? intervals[i % intervals.length] : intervals[0];
        interval = parseInt(interval);
        dateTime = dateTime.add(interval, "minutes");
        console.log(dateTime.format("hh:mm A"), interval);
      }
    };

    if (split_tee_times_side === "1") {
      const firstNineTime = moment(first_nine_time);
      const lastNineTime = moment(last_nine_time);
      console.log(firstNineTime.format("hh:mm A"));

      const teeGroupsSize = $(".tee-groups .tee-group").length;
      const groups = window.sliceIntoChunks(
        $(".tee-groups .tee-group"),
        teeGroupsSize - parseInt(teeGroupsSize / 2)
      );
      const firstNineGroups = groups[0];
      const lastNineGroups = groups[1];

      setTeeTimes(firstNineGroups.toArray(), firstNineTime);
      setTeeTimes(lastNineGroups.toArray(), lastNineTime);

      firstNineGroups.each((_, element) => {
        $(element).find("[name*='holes[']").val(1);
      });
      lastNineGroups.each((_, element) => {
        $(element).find("[name*='holes[']").val(10);
      });
    } else {
      setTeeTimes($(timesTarget()).toArray(), firstDateTime);
      $(".teams [name*='holes[']").val(first_hole);
    }

    closeModal();
  };

  if (typeof window !== "undefined") {
    window.outingEventsAutoTeeTimesApp = outingEventsAutoTeeTimesApp;
  }
})();
